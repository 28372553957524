<template>
  <div class="mm">
    <div class="video-panel" v-if="cos.fileType == 'mp4'">
      <video id="player" ref="player" style="width:100%" :src="cos.url" controls controlsList="nodownload"></video>
      <div class="control" @click="onSpeed">
        <div class="speed">{{ speed }}</div>
        <div>x</div>
      </div>
    </div>
    <div class="audio-panel" v-if="cos.fileType == 'mp3'">
      <audio ref="audioPlayer" :src="cos.url" controls="controls" controlsList="nodownload">
        Your browser does not support the audio element.
      </audio>
      <div class="control" @click="onAudioSpeed">
        <div class="speed">{{ audioSpeed }}</div>
        <div>x</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cos: Object,
  },
  components: {},
  data() {
    return {
      speed: 1,
      audioSpeed: 1,
    };
  },
  methods: {
    onSpeed() {
      this.speed += 0.25;
      if (this.speed > 2) {
        this.speed = 0.5;
      }
      let videoObj = this.$refs.player;
      if (videoObj) {
        videoObj.playbackRate = this.speed;
      }
    },
    onAudioSpeed() {
      this.audioSpeed += 0.25;
      if (this.audioSpeed > 2) {
        this.audioSpeed = 0.5;
      }
      let audioObj = this.$refs.audioPlayer;
      if (audioObj) {
        audioObj.playbackRate = this.audioSpeed;
      }
    },
  },
  mounted() {
    // let node = document.querySelectorAll('video');
    // if (node) {
    //     node[0]['disablePictureInPicture'] = true;
    // }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.mm {
  max-width: 100%;
  .video-panel {
    width: 100%;
    position: relative;
    video {
      width: 100%;
    }
    video::-internal-media-controls-download-button {
      display: none;
    }

    video::-webkit-media-controls-enclosure {
      overflow: hidden;
    }

    video::-webkit-media-controls-panel {
      width: calc(100% + 30px); /* Adjust as needed */
    }
    .control {
      position: absolute;
      width: 20px;
      height: 40px;
      right: 5px;
      bottom: 100px;
      z-index: 11;
      border-radius: 6px;
      font-size: 11px;
      color: #ffffff;
      border: 1px solid #4d52d1;
      background: #4d52d1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .audio-panel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    audio {
      width: 100%;
    }
    .control {
      width: 30px;
      height: 20px;
      border-radius: 6px;
      font-size: 11px;
      color: #ffffff;
      border: 1px solid #4d52d1;
      background: #4d52d1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
